<template>
  <div class="Login">
    <v-card
    class="mx-auto my-5"
    max-width="344"
  >
    <v-img
      src="../assets/logoTerapias.jpg"
      class="mx-auto"
      
    ></v-img>

    <v-card-title
    justify="center"
    class="text-center ma-auto"
    >
      Inicio de Sesion
    </v-card-title>

    <v-card-subtitle>
      Ingrese sus credenciales para ingresar
    </v-card-subtitle>
    <v-form
    class="pa-5"
    >
        <v-text-field
            v-model="user.email"
            label="Email"
            type="email"
            prepend-icon="mdi-email"

        ></v-text-field>
        <v-text-field
            v-model="user.password"
            label="Password"
            type="password"
            prepend-icon="mdi-lock"
        ></v-text-field>

            <v-row
    justify="center"
    >
      <v-btn
        class="my-5"
        color="primary"
        @click="submit"
      >
        Ingresar
      </v-btn>
    </v-row>

    </v-form>







      <v-spacer></v-spacer>

  </v-card>

  </div>
</template>

<script>
import {mapActions} from 'vuex'
import store from '@/store'
import Swal from 'sweetalert2'



export default {
    name: 'login',
     data() {
        return {
            user: {
                email: '', 
                password: '',
            }
        }
    },
    methods:{
        ...mapActions({
            login: 'auth/login'
        }),

        submit(){
            this.login(this.user).then(response => (
              store.getters['auth/authenticated']
            ))
            setTimeout(function(){
              if(store.getters['auth/authenticated']==null){
                Swal.fire({
                  icon: "error",
                  title: "Error al ingresar",
                  text: "El correo con la clave no coinciden",
                });
              }else{
                Swal.fire({
                  icon: "success",
                  title: "Ingreso de sesion",
                  text: "Se a ingresado con exito",
                });
              }
            }, 2000);
        }
    }
}
</script>
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import store from '@/store'

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/about",
        name: "About",

        component: () =>
            import ("../views/About.vue"),
    },
    {
        path: "/reservas",
        name: "Reservas",
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'Login'
                })
            }
            next()
        },
        component: () =>
            import ("../views/Reservas.vue"),
    },

    {
        path: "/agendar",
        name: "Agendar",
        component: () =>
            import ("../views/Agendar.vue"),
    },
    {
        path: "/categorias",
        name: "Categorias",
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'Login'
                })
            }
            next()
        },
        component: () =>
            import ("../views/Categorias.vue"),
    },
    {
        path: "/servicios",
        name: "Servicios",
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'Login'
                })
            }
            next()
        },
        component: () =>
            import ("../views/Servicios.vue"),
    },
    {
        path: "/detalle",
        name: "Detalle",
        component: () =>
            import ("../views/Detalle.vue"),
    },

];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
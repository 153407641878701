import axios from 'axios'
export default {
    namespaced: true,

    state: {
        token: null,
        user: {
            "name": null,
            "email": null,
            "role": null
        },
        config: {
            headers: {
                Authorization: ''
            }
        }
    },

    getters: {
        authenticated(state) {
            if (state.token == null) {
                state.token = localStorage.token
            }
            return state.token
        },
        user(state) {
            if (state.token == null) {
                state.token = localStorage.token
            }
            return state.user
        },
        config(state) {
            return state.config
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            localStorage.token = token
            state.config.headers.Authorization = token
            localStorage.config = state.config
            state.token = token
        },
        SET_USER(state, user) {

            localStorage.user = user
            state.user = user
        },
    },
    actions: {
        async login({ dispatch }, credentials) {
            await axios.post('login', credentials)
                .then((result) => {
                    console.log('attempt', result.data)
                    if (result.data.res == false) {
                        console.log("error login")
                        return "error login"
                    }
                    return dispatch('attempt', result.data.token)
                })
                .catch((error) => {
                    console.log(error);
                    return "error procesamiento"
                })
        },
        async attempt({ commit, state }, token) {
            if (token) {
                commit('SET_TOKEN', token)
            }
            if (!state.token) {
                return
            }
            try {
                let response = await axios.get('me', state.config)
                commit('SET_USER', response.data.data.user)
                this.$router.push("/reservas")
            } catch (e) {
                commit('SET_USER', {
                    "name": null,
                    "email": null,
                    "role": null
                })
                commit('SET_TOKEN', null)
            }
        },
        logout({ commit, state }) {
            return axios.get('logout', state.config).then(() => {
                commit('SET_USER', {
                    "name": null,
                    "email": null,
                    "role": null
                })
                commit('SET_TOKEN', null)
            })
        }
    },
    modules: {}
}
<template>
  <v-app id="inspire">
    <v-navigation-drawer
    v-model="drawer"
    app
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Terpias la Palmilla
          </v-list-item-title>
          <v-list-item-subtitle>
            Has tu reserva
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Terapias la Palmilla</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data: () => ({ drawer: null,
    items: [
          { title: 'Inicio', icon: 'mdi-view-dashboard', to: '/'},
          { title: 'Reservas', icon: 'mdi-help-box', to:'/reservas' },
          { title: 'Nosotros', icon: 'mdi-help-box', to:'/about' },
          { title: 'Agendar', icon: 'mdi-help-box', to:'/agendar' },
          { title: 'Categorias', icon: 'mdi-archive', to:'/categorias' },
          { title: 'Servicios', icon: 'mdi-book', to:'/servicios' },
        ],
        right: null,
      
     }),
    
  }
</script>